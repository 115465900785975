<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(header, i) in table.headers"
                :id="`header_${i}`"
                :key="i"
                :style="{ width: header.width, textAlign: header.textCenter ? 'center' : 'left' }"
                class="text-subtitle-2 text-truncate"
              >
                {{ header.name }}
              </th>
            </tr>
          </thead>
          <tbody v-if="pagination">
            <tr v-for="(article, i) in pagination.list" :key="i">
              <td class="text-center">
                {{ article.idx }}
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ article.users ? article.users.username : '정보없음' }}
                </span>
              </td>
              <td>
                <router-link :to="`/notice/view/${article.idx}`">
                  <span class="text-truncate">
                    {{ article.title }}
                  </span>
                </router-link>
              </td>
              <td class="text-center">
                <span v-if="new Date().getTime() - article.registerDate < 1000 * 60 * 60 * 7">
                  {{ article.registerDate | moment('from', 'now') }}
                </span>
                <span v-else>
                  {{ article.registerDate | moment('YYYY.MM.DD. HH:mm:ss') }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NoticeContent',
    props: {
      pagination: {},
      notices: {},
    },
    data () {
      return {
        table: {
          headers: [
            {
              name: '#',
              width: '10%',
              textCenter: true,
            },
            {
              name: '작성자',
              width: '15%',
              textCenter: true,
            },
            {
              name: '제목',
              width: 'auto',
              textCenter: false,
            },
            {
              name: '시간',
              width: '15%',
              textCenter: true,
            },
          ],
        },
      }
    },
    methods: {
    },
  }
</script>

<style scoped>

</style>
