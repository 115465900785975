<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <NoticeHeader />
      <NoticeContent :pagination="pagination" :board="board" />
      <v-sheet class="px-3 pb-md-3 pb-15">
        <v-row>
          <v-col cols="12" md="8" offset-md="2" class="pb-0 pb-md-0">
            <Pagination
              ref="pagination"
              :pagination="pagination"
              :show-num="7"
              base-url="/notice/"
              :query="$route.query"
              @update="getPagination"
            />
          </v-col>
          <v-col cols="12" md="2" class="text-right pt-0 pt-md-3">
            <v-dialog
              v-model="isShowWrite"
              :fullscreen="$isMobile"
              :transition="$isMobile ? 'dialog-bottom-transition' : 'scale-transition'"
              width="1080px"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  글쓰기
                </v-btn>
              </template>
              <NoticeWrite :board="board" @close-dialog="closeDialog" />
            </v-dialog>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import NoticeHeader from '@/views/notice/Header'
  import NoticeContent from '@/views/notice/Content'
  import Pagination from '@/components/core/Pagination'
  import NoticeWrite from '@/views/notice/Register'
  export default {
    name: 'Notice',
    components: { NoticeWrite, Pagination, NoticeContent, NoticeHeader },
    data () {
      return {
        isShowWrite: false,
        board: {
          idx: this.$route.params.boardIdx,
        },
        pagination: {
          list: [],
          pageNum: this.$route.query.pageNum ? this.$route.query.pageNum : 1,
        },
        pageDataSize: '30',
      }
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      closeDialog () {
        this.isShowWrite = false
        this.getPagination()
      },
      getPagination (index) {
        const data = {
          pageNum: index && index.pageNum ? index.pageNum : this.pagination.pageNum,
          pageDataSize: this.pageDataSize,
        }
        this.$axios.get('notice/notices', {
          params: data,
        })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
