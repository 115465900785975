<template>
  <v-card>
    <v-card-title class="pt-10 pt-md-3">
      공지사항 작성
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="title"
        label="Title"
      />
      <Editor ref="editor" />
    </v-card-text>
    <v-card-actions
      class="mt-10"
      :class="{
        'justify-end': !mobile,
        'd-flex': mobile,
        'pt-15': mobile,
        'flex-wrap': mobile
      }"
    >
      <v-btn
        large
        class="text-body-1"
        :class="{ 'mx-0': mobile, 'mb-2': mobile }"
        :width="mobile ? '100%' : ''"
        @click="registerNotice"
      >
        저장하기
      </v-btn>
      <v-btn
        large
        class="text-body-1"
        :class="{ 'mx-0': mobile }"
        :width="mobile ? '100%' : ''"
        @click="close"
      >
        창닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Editor from '@/components/core/Editor'
  export default {
    name: 'NoticeWrite',
    components: { Editor },
    data () {
      return {
        title: '',
      }
    },
    computed: {
      content () {
        return this.$refs.editor.getContent()
      },
      mobile () {
        return this.$isMobile
      },
    },
    methods: {
      close () {
        this.$refs.editor.setContent('')
        this.$emit('close-dialog')
      },
      async registerNotice () {
        const url = '/manage/notice/register'
        const data = {
          title: this.title,
          content: this.content,
        }
        await this.$axios.post(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('공지사항이 등록되었습니다.')
            this.close()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
    },
  }
</script>

<style scoped>

</style>
